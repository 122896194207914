import { Injectable } from '@angular/core';
import {
    BorderRadiusOption,
    DisplayOption,
    DisplayPropertyType,
    FlexAlignOption,
    FlexDirectionOption,
    FlexJustifyOption, FlexWrapOption,
    FontSizeOption,
    FontWeightOption,
    GapSizeOption,
    IBorderRadiusStyle,
    IDisplayStyle,
    IGapStyle,
    ISizeStyle,
    ISpacingStyle,
    ITypographyStyle,
    MaxWidthOption,
    ScreenSize,
    SpacingSizeOption,
    TailwindResponsivePrefixes,
    TextAlignOption,
    TextColorOption,
    IFlexStyle, BackgroundColorOption, IBackgroundColorStyle
} from '@seven1/model';


export type SpacingType = 'm' | 'p';
export type SpacingPlace = 'x' | 'y';

export type TypographyProperty = 'color' | 'fontSize' | 'fontWeight' | 'textAlign';
export type TypographyPropertyValue<T extends TypographyProperty> = ITypographyStyle[T];

@Injectable({
    providedIn: 'root'
})
export class TailwindService {
    private _getResponsivePrefix(
        screenSize: ScreenSize = '',
    ): TailwindResponsivePrefixes {
        switch (screenSize) {
            case 'tablet':
                return 'md:';
            case 'desktop':
                return 'lg:';
            default:
                return '';
        }
    }

    /* Display -------------------------------------------------------------- */
    private _getDisplayStyle(
        display?: DisplayOption,
        screenSize: ScreenSize = '',
        displayType: DisplayPropertyType = 'block',
    ): string {
        const prefix: TailwindResponsivePrefixes = this._getResponsivePrefix(screenSize);
        switch (display) {

            /**
             * hidden
             * md:hidden
             * lg:hidden
             */
            case 'hidden':
                return `${prefix}${display}`;
            /**
             * sr-only
             * md:sr-only
             * lg:sr-only
             */
            case 'sr-only':
                return `${prefix}${display}`;
            /**
             * not-sr-only
             * md:not-sr-only
             * lg:not-sr-only
             */
            case 'not-sr-only':
                return `${prefix}${display}`;
            case 'visible':
                /**
                 * block
                 * md:block
                 * lg:block
                 * flex
                 * md:flex
                 * lg:flex
                 * inline-flex
                 * md:inline-flex
                 * lg:inline-flex
                 * grid
                 * md:grid
                 * lg:grid
                 * inline-grid
                 * md:inline-grid
                 * lg:inline-grid
                 */
                return `${prefix}${displayType}`;
            case 'unset':
            default:
                return '';
        }
    }

    getDisplayClasses(
        styles: IDisplayStyle,
        displayType: DisplayPropertyType = 'block',
    ): string[] {
        const res = [];
        if (styles.display) res.push(this._getDisplayStyle(styles?.display, '', displayType));
        if (styles.displayTablet) res.push(this._getDisplayStyle(styles?.displayTablet, 'tablet', displayType));
        if (styles.displayDesktop) res.push(this._getDisplayStyle(styles?.displayDesktop, 'desktop', displayType));
        return res;
    }

    /* Spacing -------------------------------------------------------------- */
    private _getSpacingSuffix(
        spacing?: SpacingSizeOption,
        spacingType: SpacingType = 'm',
        place: SpacingPlace = 'x'
    ): string {
        const base = `${spacingType}${place}-`;
        switch (spacing) {
            case 'left':
                return `ml-0`;
            case 'right':
                return `ml-auto`;
            case 'auto':
                return `${base}auto`;
            case 'xxs':
                return `${base}0.5`;
            case 'xs':
                return `${base}1`;
            case 's':
                return `${base}2`;
            case 'md':
                return `${base}3`;
            case 'l':
                return `${base}4`;
            case 'xl':
                return `${base}5`;
            case '2xl':
                return `${base}6`;
            case '3xl':
                return `${base}8`;
            case '4xl':
                return `${base}12`;
            case '5xl':
                return `${base}16`;
            case '6xl':
                return `${base}20`;
            case '7xl':
                return `${base}24`;
            case '8xl':
                return `${base}32`;
            case '9xl':
                return `${base}40`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getSpacingStyle(
        spacing?: SpacingSizeOption,
        spacingType: SpacingType = 'm',
        place: SpacingPlace = 'x',
        screenSize: ScreenSize = '',
    ): string {
        return `${this._getResponsivePrefix(screenSize)}${this._getSpacingSuffix(spacing, spacingType, place)}`
    }

    getSpacingClasses(
        styles: ISpacingStyle,
    ): string[] {
        const res: string[] = [];
        for (const [key, value] of Object.entries(styles)) {
            if (!value || value === 'unset' || (!key.startsWith('margin') && !key.startsWith('padding'))) {
                continue;
            }

            /**
             * ml-0 mr-0 ml-auto
             * md:ml-0 md:mr-0 md:ml-auto
             * lg:ml-0 lg:mr-0 lg:ml-auto
             *
             * my-auto mx-auto px-auto py-auto
             * md:my-auto md:mx-auto md:px-auto md:py-auto
             * lg:my-auto lg:mx-auto lg:px-auto lg:py-auto
             *
             * my-0.5 mx-0.5 px-0.5 py-0.5
             * md:my-0.5 md:mx-0.5 md:px-0.5 md:py-0.5
             * lg:my-0.5 lg:mx-0.5 lg:px-0.5 lg:py-0.5
             *
             * my-1 mx-1 px-1 py-1
             * md:my-1 md:mx-1 md:px-1 md:py-1
             * lg:my-1 lg:mx-1 lg:px-1 lg:py-1
             *
             * my-2 mx-2 px-2 py-2
             * md:my-2 md:mx-2 md:px-2 md:py-2
             * lg:my-2 lg:mx-2 lg:px-2 lg:py-2
             *
             * my-3 mx-3 px-3 py-3
             * md:my-3 md:mx-3 md:px-3 md:py-3
             * lg:my-3 lg:mx-3 lg:px-3 lg:py-3
             *
             * my-4 mx-4 px-4 py-4
             * md:my-4 md:mx-4 md:px-4 md:py-4
             * lg:my-4 lg:mx-4 lg:px-4 lg:py-4
             *
             * my-5 mx-5 px-5 py-5
             * md:my-5 md:mx-5 md:px-5 md:py-5
             * lg:my-5 lg:mx-5 lg:px-5 lg:py-5
             *
             * my-6 mx-6 px-6 py-6
             * md:my-6 md:mx-6 md:px-6 md:py-6
             * lg:my-6 lg:mx-6 lg:px-6 lg:py-6
             *
             * my-8 mx-8 px-8 py-8
             * md:my-8 md:mx-8 md:px-8 md:py-8
             * lg:my-8 lg:mx-8 lg:px-8 lg:py-8
             *
             * my-12 mx-12 px-12 py-12
             * md:my-12 md:mx-12 md:px-12 md:py-12
             * lg:my-12 lg:mx-12 lg:px-12 lg:py-12
             *
             * my-16 mx-16 px-16 py-16
             * md:my-16 md:mx-16 md:px-16 md:py-16
             * lg:my-16 lg:mx-16 lg:px-16 lg:py-16
             *
             * my-20 mx-20 px-20 py-20
             * md:my-20 md:mx-20 md:px-20 md:py-20
             * lg:my-20 lg:mx-20 lg:px-20 lg:py-20
             *
             * my-24 mx-24 px-24 py-24
             * md:my-24 md:mx-24 md:px-24 md:py-24
             * lg:my-24 lg:mx-24 lg:px-24 lg:py-24
             *
             * my-32 mx-32 px-32 py-32
             * md:my-32 md:mx-32 md:px-32 md:py-32
             * lg:my-32 lg:mx-32 lg:px-32 lg:py-32
             *
             * my-40 mx-40 px-40 py-40
             * md:my-40 md:mx-40 md:px-40 md:py-40
             * lg:my-40 lg:mx-40 lg:px-40 lg:py-40
             */
            const type: SpacingType = key.startsWith('margin') ? 'm' : 'p';
            const typeName = type === 'm' ? 'margin' : 'padding';
            const place = key.startsWith(typeName + 'X') ? 'x' : 'y';
            const screenSize: ScreenSize = key.endsWith('Tablet') ? 'tablet' : key.endsWith('Desktop') ? 'desktop' : '';
            res.push(this._getSpacingStyle(value, type, place, screenSize));
        }
        return res;
    }

    /* Size ----------------------------------------------------------------- */
    private _getSizeStyle(
        size: MaxWidthOption,
        screenSize: ScreenSize = ''
    ): string {
        const prefix = this._getResponsivePrefix(screenSize);
        switch (size) {
            case '25%':
                /**
                 * max-w-1/4
                 * md:max-w-1/4
                 * lg:max-w-1/4
                 */
                return `${prefix}max-w-1/4`;
            case '50%':
                /**
                 * max-w-1/2
                 * md:max-w-1/2
                 * lg:max-w-1/2
                 */
                return `${prefix}max-w-1/2`;
            case '100%':
                /**
                 * max-w-full
                 * md:max-w-full
                 * lg:max-w-full
                 */
                return `${prefix}max-w-full`;
            case '1rem':
                /**
                 * max-w-4
                 * md:max-w-4
                 * lg:max-w-4
                 */
                return `${prefix}max-w-4`;
            case '2rem':
                /**
                 * max-w-8
                 * md:max-w-8
                 * lg:max-w-8
                 */
                return `${prefix}max-w-8`;
            case '3rem':
                /**
                 * max-w-12
                 * md:max-w-12
                 * lg:max-w-12
                 */
                return `${prefix}max-w-12`;
            case '4rem':
                /**
                 * max-w-16
                 * md:max-w-16
                 * lg:max-w-16
                 */
                return `${prefix}max-w-16`;
            case '5rem':
                /**
                 * max-w-20
                 * md:max-w-20
                 * lg:max-w-20
                 */
                return `${prefix}max-w-20`;
            case '6rem':
                /**
                 * max-w-24
                 * md:max-w-24
                 * lg:max-w-24
                 */
                return `${prefix}max-w-24`;
            case '7rem':
                /**
                 * max-w-28
                 * md:max-w-28
                 * lg:max-w-28
                 */
                return `${prefix}max-w-28`;
            case '8rem':
                /**
                 * max-w-32
                 * md:max-w-32
                 * lg:max-w-32
                 */
                return `${prefix}max-w-32`;
            case '9rem':
                /**
                 * max-w-36
                 * md:max-w-36
                 * lg:max-w-36
                 */
                return `${prefix}max-w-36`;
            case '10rem':
                /**
                 * max-w-40
                 * md:max-w-40
                 * lg:max-w-40
                 */
                return `${prefix}max-w-40`;
            case '11rem':
                /**
                 * max-w-44
                 * md:max-w-44
                 * lg:max-w-44
                 */
                return `${prefix}max-w-44`;
            case '12rem':
                /**
                 * max-w-48
                 * md:max-w-48
                 * lg:max-w-48
                 */
                return `${prefix}max-w-48`;
            case '14rem':
                /**
                 * max-w-56
                 * md:max-w-56
                 * lg:max-w-56
                 */
                return `${prefix}max-w-56`;
            case '16rem':
                /**
                 * max-w-64
                 * md:max-w-64
                 * lg:max-w-64
                 */
                return `${prefix}max-w-64`;
            case '18rem':
                /**
                 * max-w-72
                 * md:max-w-72
                 * lg:max-w-72
                 */
                return `${prefix}max-w-72`;
            case '20rem':
                /**
                 * max-w-xs
                 * md:max-w-xs
                 * lg:max-w-xs
                 */
                return `${prefix}max-w-xs`;
            case '24rem':
                /**
                 * max-w-sm
                 * md:max-w-sm
                 * lg:max-w-sm
                 */
                return `${prefix}max-w-sm`;
            case '28rem':
                /**
                 * max-w-md
                 * md:max-w-md
                 * lg:max-w-md
                 */
                return `${prefix}max-w-md`;
            case '32rem':
                /**
                 * max-w-lg
                 * md:max-w-lg
                 * lg:max-w-lg
                 */
                return `${prefix}max-w-lg`;
            case '36rem':
                /**
                 * max-w-xl
                 * md:max-w-xl
                 * lg:max-w-xl
                 */
                return `${prefix}max-w-xl`;
            case '42rem':
                /**
                 * max-w-2xl
                 * md:max-w-2xl
                 * lg:max-w-2xl
                 */
                return `${prefix}max-w-2xl`;
            case '48rem':
                /**
                 * max-w-3xl
                 * md:max-w-3xl
                 * lg:max-w-3xl
                 */
                return `${prefix}max-w-3xl`;
            case '56rem':
                /**
                 * max-w-4xl
                 * md:max-w-4xl
                 * lg:max-w-4xl
                 */
                return `${prefix}max-w-4xl`;
            case '64rem':
                /**
                 * max-w-5xl
                 * md:max-w-5xl
                 * lg:max-w-5xl
                 */
                return `${prefix}max-w-5xl`;
            case 'unset':
            default:
                return '';
        }
    }

    getSizeClasses(
        styles: ISizeStyle,
    ): string[] {
        const res: string[] = [];
        if (styles.maxWidth) res.push(this._getSizeStyle(styles.maxWidth));
        if (styles.maxWidthTablet) res.push(this._getSizeStyle(styles.maxWidthTablet, 'tablet'));
        if (styles.maxWidthDesktop) res.push(this._getSizeStyle(styles.maxWidthDesktop, 'desktop'));
        return res;
    }

    /* Typography ----------------------------------------------------------- */
    private _getColorStyle(
        color: TextColorOption
    ): string {
        switch (color) {
            case 'primary':
                /**
                 * text-primary
                 * md:text-primary
                 * lg:text-primary
                 * */
                return `text-primary`;
            case 'secondary':
                /**
                 * text-secondary
                 * md:text-secondary
                 * lg:text-secondary
                 * */
                return `text-secondary`;
            case 'accent-light':
                /**
                 * text-accent-light
                 * md:text-accent-light
                 * lg:text-accent-light
                 * */
                return `text-accent-light`;
            case 'accent-dark':
                /**
                 * text-accent-dark
                 * md:text-accent-dark
                 * lg:text-accent-dark
                 * */
                return `text-accent-dark`;
            case 'grey-dark':
                /**
                 * text-grey-dark
                 * md:text-grey-dark
                 * lg:text-grey-dark
                 * */
                return `text-grey-dark`;
            case 'grey-light':
                /**
                 * text-grey-light
                 * md:text-grey-light
                 * lg:text-grey-light
                 * */
                return `text-grey-light`;
            case 'white':
                /**
                 * text-white
                 * md:text-white
                 * lg:text-white
                 * */
                return `text-white`;
            case 'success':
                /**
                 * text-success
                 * md:text-success
                 * lg:text-md:text-success
                 * */
                return `text-success`;
            case 'warning':
                /**
                 * text-warning
                 * md:text-warning
                 * lg:text-md:text-warning
                 * */
                return `text-warning`;
            case 'error':
                /**
                 * text-error
                 * md:text-error
                 * lg:text-md:text-error
                 * */
                return `text-error`;
            case 'inherit':
                /**
                 * text-inherit
                 * md:text-inherit
                 * lg:text-inherit
                 * */
                return `text-inherit`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getFontWeightStyle(
        fontWeight: FontWeightOption
    ): string {
        switch (fontWeight) {
            case 'thin':
                /**
                 * font-thin
                 * md:font-thin
                 * lg:font-thin
                 * */
                return `font-thin`;
            case 'extralight':
                /**
                 * font-extralight
                 * md:font-extralight
                 * lg:font-extralight
                 * */
                return `font-extralight`;
            case 'light':
                /**
                 * font-light
                 * md:font-light
                 * lg:font-light
                 * */
                return `font-light`;
            case 'normal':
                /**
                 * font-normal
                 * md:font-normal
                 * lg:font-normal
                 * */
                return `font-normal`;
            case 'medium':
                /**
                 * font-medium
                 * md:font-medium
                 * lg:font-medium
                 * */
                return `font-medium`;
            case 'semibold':
                /**
                 * font-semibold
                 * md:font-semibold
                 * lg:font-semibold
                 * */
                return `font-semibold`;
            case 'bold':
                /**
                 * font-bold
                 * md:font-bold
                 * lg:font-bold
                 * */
                return `font-bold`;
            case 'extrabold':
                /**
                 * font-extrabold
                 * md:font-extrabold
                 * lg:font-extrabold
                 * */
                return `font-extrabold`;
            case 'black':
                /**
                 * font-black
                 * md:font-black
                 * lg:font-black
                 * */
                return `font-black`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getFontSizeStyle(
        fontSize: FontSizeOption
    ): string {
        switch (fontSize) {
            case 'xs':
                /**
                 * text-xs
                 * md:text-xs
                 * lg:text-xs
                 * */
                return `text-xs`;
            case 'sm':
                /**
                 * text-sm
                 * md:text-sm
                 * lg:text-sm
                 * */
                return `text-sm`;
            case 'base':
                /**
                 * text-base
                 * md:text-base
                 * lg:text-base
                 * */
                return `text-base`;
            case 'lg':
                /**
                 * text-lg
                 * md:text-lg
                 * lg:text-lg
                 * */
                return `text-lg`;
            case 'xl':
                /**
                 * text-xl
                 * md:text-xl
                 * lg:text-xl
                 * */
                return `text-xl`;
            case '2xl':
                /**
                 * text-2xl
                 * md:text-2xl
                 * lg:text-2xl
                 * */
                return `text-2xl`;
            case '3xl':
                /**
                 * text-3xl
                 * md:text-3xl
                 * lg:text-3xl
                 * */
                return `text-3xl`;
            case '4xl':
                /**
                 * text-4xl
                 * md:text-4xl
                 * lg:text-4xl
                 * */
                return `text-4xl`;
            case '5xl':
                /**
                 * text-5xl
                 * md:text-5xl
                 * lg:text-5xl
                 * */
                return `text-5xl`;
            case '6xl':
                /**
                 * text-6xl
                 * md:text-6xl
                 * lg:text-6xl
                 * */
                return `text-6xl`;
            case '7xl':
                /**
                 * text-7xl
                 * md:text-7xl
                 * lg:text-7xl
                 * */
                return `text-7xl`;
            case '8xl':
                /**
                 * text-8xl
                 * md:text-8xl
                 * lg:text-8xl
                 * */
                return `text-8xl`;
            case '9xl':
                /**
                 * text-9xl
                 * md:text-9xl
                 * lg:text-9xl
                 * */
                return `text-9xl`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getTextAlignStyle(
        textAlign: TextAlignOption
    ): string {
        switch (textAlign) {
            case 'left':
                /**
                 * text-left
                 * md:text-left
                 * lg:text-left
                 * */
                return `text-left`;
            case 'center':
                /**
                 * text-center
                 * md:text-center
                 * lg:text-center
                 * */
                return `text-center`;
            case 'right':
                /**
                 * text-right
                 * md:text-right
                 * lg:text-right
                 * */
                return `text-right`;
            case 'unset':
            default:
                return '';
        }
    }

    private _getTypographyStyle<T extends TypographyProperty>(
        property: T,
        typo: TypographyPropertyValue<T>,
        screenSize: ScreenSize = '',
    ): string {
        const prefix = this._getResponsivePrefix(screenSize);
        if (typo && typo !== 'unset') {
            switch (property) {
                case 'color':
                    return `${prefix}${this._getColorStyle(typo as TextColorOption)}`;
                case 'fontWeight':
                    return `${prefix}${this._getFontWeightStyle(typo as FontWeightOption)}`;
                case 'fontSize':
                    return `${prefix}${this._getFontSizeStyle(typo as FontSizeOption)}`;
                case 'textAlign':
                    return `${prefix}${this._getTextAlignStyle(typo as TextAlignOption)}`;
            }
        }
        return '';
    }

    getTypographyClasses(
        styles: ITypographyStyle
    ): string[] {
        const res: string[] = [];
        if (styles.color) res.push(this._getTypographyStyle('color', styles.color));
        if (styles.colorTablet) res.push(this._getTypographyStyle('color', styles.colorTablet, 'tablet'));
        if (styles.colorDesktop) res.push(this._getTypographyStyle('color', styles.colorDesktop, 'desktop'));

        if (styles.fontSize) res.push(this._getTypographyStyle('fontSize', styles.fontSize));
        if (styles.fontSizeTablet) res.push(this._getTypographyStyle('fontSize', styles.fontSizeTablet, 'tablet'));
        if (styles.fontSizeDesktop) res.push(this._getTypographyStyle('fontSize', styles.fontSizeDesktop, 'desktop'));

        if (styles.textAlign) res.push(this._getTypographyStyle('textAlign', styles.textAlign));
        if (styles.textAlignTablet) res.push(this._getTypographyStyle('textAlign', styles.textAlignTablet, 'tablet'));
        if (styles.textAlignDesktop) res.push(this._getTypographyStyle('textAlign', styles.textAlignDesktop, 'desktop'));

        if (styles.fontWeight) res.push(this._getTypographyStyle('fontWeight', styles.fontWeight));
        return res;
    }

    private _getBorderRadiusStyle(
        radius: BorderRadiusOption,
        screenSize: ScreenSize = ''
    ): string {
        const prefix = this._getResponsivePrefix(screenSize);
        switch (radius) {
            case 'none':
                /**
                 * rounded-none
                 * md:rounded-none
                 * lg:rounded-none
                 */
                return prefix + 'rounded-none';
            case 'sm':
                /**
                 * rounded-sm
                 * md:rounded-sm
                 * lg:rounded-sm
                 */
                return prefix + 'rounded-sm';
            case 'md':
                /**
                 * rounded-md
                 * md:rounded-md
                 * lg:rounded-md
                 */
                return prefix + 'rounded-md';
            case 'lg':
                /**
                 * rounded-lg
                 * md:rounded-lg
                 * lg:rounded-lg
                 */
                return prefix + 'rounded-lg';
            case 'xl':
                /**
                 * rounded-xl
                 * md:rounded-xl
                 * lg:rounded-xl
                 */
                return prefix + 'rounded-xl';
            case '2xl':
                /**
                 * rounded-2xl
                 * md:rounded-2xl
                 * lg:rounded-2xl
                 */
                return prefix + 'rounded-2xl';
            case '3xl':
                /**
                 * rounded-3xl
                 * md:rounded-3xl
                 * lg:rounded-3xl
                 */
                return prefix + 'rounded-3xl';
            case 'full':
                /**
                 * rounded-full
                 * md:rounded-full
                 * lg:rounded-full
                 */
                return prefix + 'rounded-full';
            case 'unset':
            default:
                return '';
        }
    }

    /* Border Radius -------------------------------------------------------- */
    getBorderRadiusClasses(
        styles: IBorderRadiusStyle,
    ): string[] {
        const res: string[] = [];
        if (styles.radius) res.push(this._getBorderRadiusStyle(styles.radius));
        if (styles.radiusTablet) res.push(this._getBorderRadiusStyle(styles.radiusTablet, 'tablet'));
        if (styles.radiusDesktop) res.push(this._getBorderRadiusStyle(styles.radiusDesktop, 'desktop'));
        return res;
    }

    /* Gap ------------------------------------------------------------------ */
    private _getGapStyle(
        gap: GapSizeOption,
        orientation: 'x' | 'y' = 'x',
        screenSize: ScreenSize = ''
    ): string {
        const base = `${this._getResponsivePrefix(screenSize)}gap-${orientation}`;
        switch (gap) {
            case 'xxs':
                /**
                 * gap-x-0.5 gap-y-0.5
                 * md:gap-x-0.5 md:gap-y-0.5
                 * lg:gap-x-0.5 lg:gap-y-0.5
                 * */
                return `${base}-0.5`;
            case 'xs':
                /**
                 * gap-x-1 gap-y-1
                 * md:gap-x-1 md:gap-y-1
                 * lg:gap-x-1 lg:gap-y-1
                 * */
                return `${base}-1`;
            case 's':
                /**
                 * gap-x-2 gap-y-2
                 * md:gap-x-2 md:gap-y-2
                 * lg:gap-x-2 lg:gap-y-2
                 * */
                return `${base}-2`;
            case 'md':
                /**
                 * gap-x-4 gap-y-4
                 * md:gap-x-4 md:gap-y-4
                 * lg:gap-x-4 lg:gap-y-4
                 * */
                return `${base}-4`;
            case 'l':
                /**
                 * gap-x-5 gap-y-5
                 * md:gap-x-5 md:gap-y-5
                 * lg:gap-x-5 lg:gap-y-5
                 * */
                return `${base}-5`;
            case 'xl':
                /**
                 * gap-x-6 gap-y-6
                 * md:gap-x-6 md:gap-y-6
                 * lg:gap-x-6 lg:gap-y-6
                 * */
                return `${base}-6`;
            case '2xl':
                /**
                 * gap-x-7 gap-y-7
                 * md:gap-x-7 md:gap-y-7
                 * lg:gap-x-7 lg:gap-y-7
                 * */
                return `${base}-7`;
            case '3xl':
                /**
                 * gap-x-8 gap-y-8
                 * md:gap-x-8 md:gap-y-8
                 * lg:gap-x-8 lg:gap-y-8
                 * */
                return `${base}-8`;
            case '4xl':
                /**
                 * gap-x-9 gap-y-9
                 * md:gap-x-9 md:gap-y-9
                 * lg:gap-x-9 lg:gap-y-9
                 * */
                return `${base}-9`;
            case '5xl':
                /**
                 * gap-x-10 gap-y-10
                 * md:gap-x-10 md:gap-y-10
                 * lg:gap-x-10 lg:gap-y-10
                 * */
                return `${base}-10`;
            case '6xl':
                /**
                 * gap-x-11 gap-y-11
                 * md:gap-x-11 md:gap-y-11
                 * lg:gap-x-11 lg:gap-y-11
                 * */
                return `${base}-11`;
            case '7xl':
                /**
                 * gap-x-12 gap-y-12
                 * md:gap-x-12 md:gap-y-12
                 * lg:gap-x-12 lg:gap-y-12
                 * */
                return `${base}-12`;
            case '8xl':
                /**
                 * gap-x-13 gap-y-13
                 * md:gap-x-13 md:gap-y-13
                 * lg:gap-x-13 lg:gap-y-13
                 * */
                return `${base}-13`;
            case '9xl':
                /**
                 * gap-x-14 gap-y-14
                 * md:gap-x-14 md:gap-y-14
                 * lg:gap-x-14 lg:gap-y-14
                 * */
                return `${base}-14`;
            case 'none':
                /**
                 * gap-x-0 gap-y-0
                 * md:gap-x-0 md:gap-y-0
                 * lg:gap-x-0 lg:gap-y-0
                 * */
                return `${base}-0`
            default:
                return '';
        }
    }

    getGapClasses(
        styles: IGapStyle,
    ): string[] {
        const res: string[] = [];
        if (styles.gapX) res.push(this._getGapStyle(styles.gapX));
        if (styles.gapXTablet) res.push(this._getGapStyle(styles.gapXTablet, 'x', 'tablet'));
        if (styles.gapXDesktop) res.push(this._getGapStyle(styles.gapXDesktop, 'x', 'desktop'));

        if (styles.gapY) res.push(this._getGapStyle(styles.gapY, 'y'));
        if (styles.gapYTablet) res.push(this._getGapStyle(styles.gapYTablet, 'y', 'tablet'));
        if (styles.gapYDesktop) res.push(this._getGapStyle(styles.gapYDesktop, 'y', 'desktop'));
        return res;
    }


    private _getFlexDirectionStyle(
        direction?: FlexDirectionOption,
        screenSize: ScreenSize = ''
    ): string {
        const base = `${this._getResponsivePrefix(screenSize)}`;
        switch (direction) {
            case 'unset':
                return '';
            case 'row':
                /**
                 * flex-row
                 * md:flex-row
                 * lg:flex-row
                 */
                return `${base}flex-row`;
            case 'row-reverse':
                /**
                 * flex-row-reverse
                 * md:flex-row-reverse
                 * lg:flex-row-reverse
                 */
                return `${base}flex-row-reverse`;
            case 'column':
                /**
                 * flex-col
                 * md:flex-col
                 * lg:flex-col
                 */
                return `${base}flex-col`;
            case 'column-reverse':
                /**
                 * flex-col-reverse
                 * md:flex-col-reverse
                 * lg:flex-col-reverse
                 */
                return `${base}flex-col-reverse`;
            default:
                return '';
        }
    }

    private _getFlexAlignStyle(
        align?: FlexAlignOption,
        screenSize: ScreenSize = ''
    ): string {
        const base = `${this._getResponsivePrefix(screenSize)}`;
        switch (align) {
            case 'unset':
                return '';
            case 'flex-start':
                /**
                 * items-start
                 * md:items-start
                 * lg:items-start
                 */
                return `${base}items-start`;
            case 'center':
                /**
                 * items-center
                 * md:items-center
                 * lg:items-center
                 */
                return `${base}items-center`;
            case 'flex-end':
                /**
                 * items-end
                 * md:items-end
                 * lg:items-end
                 */
                return `${base}items-end`;
            case 'baseline':
                /**
                 * items-baseline
                 * md:items-baseline
                 * lg:items-baseline
                 */
                return `${base}items-baseline`;
            case 'stretch':
                /**
                 * items-stretch
                 * md:items-stretch
                 * lg:items-stretch
                 */
                return `${base}items-stretch`;
            default:
                return '';
        }
    }

    private _getFlexJustifyStyle(
        justify?: FlexJustifyOption,
        screenSize: ScreenSize = ''
    ): string {
        const base = `${this._getResponsivePrefix(screenSize)}`;
        switch (justify) {
            case 'unset':
                return '';
            case 'flex-start':
                /**
                 * justify-start
                 * md:justify-start
                 * lg:justify-start
                 */
                return `${base}justify-start`;
            case 'center':
                /**
                 * justify-center
                 * md:justify-center
                 * lg:justify-center
                 */
                return `${base}justify-center`;
            case 'flex-end':
                /**
                 * justify-end
                 * md:justify-end
                 * lg:justify-end
                 */
                return `${base}justify-end`;
            case 'space-between':
                /**
                 * justify-between
                 * md:justify-between
                 * lg:justify-between
                 */
                return `${base}justify-between`;
            case 'space-around':
                /**
                 * justify-around
                 * md:justify-around
                 * lg:justify-around
                 */
                return `${base}justify-around`;
            case 'space-evenly':
                /**
                 * justify-evenly
                 * md:justify-evenly
                 * lg:justify-evenly
                 */
                return `${base}justify-evenly`;
            default:
                return '';
        }
    }


    private _getFlexWrapStyle(
        wrap?: FlexWrapOption,
        screenSize: ScreenSize = ''
    ): string {
        const base = `${this._getResponsivePrefix(screenSize)}`;
        switch (wrap) {
            case 'unset':
                return '';
            case 'wrap':
                /**
                 * flex-wrap
                 * md:flex-wrap
                 * lg:flex-wrap
                 */
                return `${base}flex-wrap`;
            case 'wrap-reverse':
                /**
                 * flex-wrap-reverse
                 * md:flex-wrap-reverse
                 * lg:flex-wrap-reverse
                 */
                return `${base}flex-wrap-reverse`;
            case 'nowrap':
                /**
                 * flex-nowrap
                 * md:flex-nowrap
                 * lg:flex-nowrap
                 */
                return `${base}flex-nowrap`;
            default:
                return '';
        }
    }

    // TODO: Check if they are different from grid gaps. Maybe 2 different functions are not needed.

    private _getFlexGapStyle(
        gap?: GapSizeOption,
        screenSize: ScreenSize = ''
    ): string {
        const prefix = `${this._getResponsivePrefix(screenSize)}`;
        switch (gap) {
            case 'xxs':
                /**
                 * gap-0.5
                 * md:gap-0.5
                 * lg:gap-0.5
                 */
                return `${prefix}gap-0.5`;
            case 'xs':
                /**
                 * gap-1
                 * md:gap-1
                 * lg:gap-1
                 */
                return `${prefix}gap-1`;
            case 's':
                /**
                 * gap-2
                 * md:gap-2
                 * lg:gap-2
                 */
                return `${prefix}gap-2`;
            case 'md':
                /**
                 * gap-3
                 * md:gap-3
                 * lg:gap-3
                 */
                return `${prefix}gap-3`;
            case 'l':
                /**
                 * gap-4
                 * md:gap-4
                 * lg:gap-4
                 */
                return `${prefix}gap-4`;
            case 'xl':
                /**
                 * gap-5
                 * md:gap-5
                 * lg:gap-5
                 */
                return `${prefix}gap-5`;
            case '2xl':
                /**
                 * gap-6
                 * md:gap-6
                 * lg:gap-6
                 */
                return `${prefix}gap-6`;
            case '3xl':
                /**
                 * gap-8
                 * md:gap-8
                 * lg:gap-8
                 */
                return `${prefix}gap-8`;
            case '4xl':
                /**
                 * gap-12
                 * md:gap-12
                 * lg:gap-12
                 */
                return `${prefix}gap-12`;
            case '5xl':
                /**
                 * gap-16
                 * md:gap-16
                 * lg:gap-16
                 */
                return `${prefix}gap-16`;
            case '6xl':
                /**
                 * gap-20
                 * md:gap-20
                 * lg:gap-20
                 */
                return `${prefix}gap-20`;
            case '7xl':
                /**
                 * gap-24
                 * md:gap-24
                 * lg:gap-24
                 */
                return `${prefix}gap-24`;
            case '8xl':
                /**
                 * gap-32
                 * md:gap-32
                 * lg:gap-32
                 */
                return `${prefix}gap-32`;
            case '9xl':
                /**
                 * gap-40
                 * md:gap-40
                 * lg:gap-40
                 */
                return `${prefix}gap-40`;
            default:
                return '';
        }
    }

    getFlexboxClasses(
        styles: IFlexStyle,
    ): string[] {
        const res: string[] = [];
        if (styles.flexDirection) res.push(this._getFlexDirectionStyle(styles.flexDirection));
        if (styles.flexDirectionTablet) res.push(this._getFlexDirectionStyle(styles.flexDirectionTablet, 'tablet'));
        if (styles.flexDirectionDesktop) res.push(this._getFlexDirectionStyle(styles.flexDirectionDesktop, 'desktop'));

        if (styles.flexAlign) res.push(this._getFlexAlignStyle(styles.flexAlign));
        if (styles.flexAlignTablet) res.push(this._getFlexAlignStyle(styles.flexAlignTablet, 'tablet'));
        if (styles.flexAlignDesktop) res.push(this._getFlexAlignStyle(styles.flexAlignDesktop, 'desktop'));

        if (styles.flexJustify) res.push(this._getFlexJustifyStyle(styles.flexJustify));
        if (styles.flexJustifyTablet) res.push(this._getFlexJustifyStyle(styles.flexJustifyTablet, 'tablet'));
        if (styles.flexJustifyDesktop) res.push(this._getFlexJustifyStyle(styles.flexJustifyDesktop, 'desktop'));

        if (styles.flexGap) res.push(this._getFlexGapStyle(styles.flexGap));
        if (styles.flexGapTablet) res.push(this._getFlexGapStyle(styles.flexGapTablet, 'tablet'));
        if (styles.flexGapDesktop) res.push(this._getFlexGapStyle(styles.flexGapDesktop, 'desktop'));

        if (styles.flexWrap) res.push(this._getFlexWrapStyle(styles.flexWrap));
        return res;
    }


    private _getBackgroundColorStyle(
        color?: BackgroundColorOption,
        screenSize: ScreenSize = ''
    ): string {
        const prefix = `${this._getResponsivePrefix(screenSize)}`;
        switch (color) {
            case 'primary':
                /**
                 * bg-primary
                 * md:bg-primary
                 * lg:bg-primary
                 */
                return `${prefix}bg-primary`;
            case 'secondary':
                /**
                 * bg-secondary
                 * md:bg-secondary
                 * lg:bg-secondary
                 */
                return `${prefix}bg-secondary`;
            case 'accent-light':
                /**
                 * bg-accent-light
                 * md:bg-accent-light
                 * lg:bg-accent-light
                 */
                return `${prefix}bg-accent-light`;
            case 'accent-dark':
                /**
                 * bg-accent-dark
                 * md:bg-accent-dark
                 * lg:bg-accent-dark
                 */
                return `${prefix}bg-accent-dark`;
            case 'grey-dark':
                /**
                 * bg-grey-dark
                 * md:bg-grey-dark
                 * lg:bg-grey-dark
                 */
                return `${prefix}bg-grey`;
            case 'grey-light':
                /**
                 * bg-grey-light
                 * md:bg-grey-light
                 * lg:bg-grey-light
                 */
                return `${prefix}bg-grey`;
            case 'white':
                /**
                 * bg-white
                 * md:bg-white
                 * lg:bg-white
                 */
                return `${prefix}bg-white`;
            case 'transparent':
                /**
                 * bg-transparent
                 * md:bg-transparent
                 * lg:bg-transparent
                 */
                return `${prefix}bg-transparent`;
            default:
                return '';
        }
    }

    getBackgroundColorClasses(
        styles: IBackgroundColorStyle,
    ): string[] {
        const res: string[] = [];
        if (styles.backgroundColor) res.push(this._getBackgroundColorStyle(styles.backgroundColor));
        return res;
    }
}
