import { Component, computed, inject, input } from '@angular/core';
import { IBadgeBlock } from './badge.model';
import { TailwindService } from '../../../../service';

@Component({
    selector: 'app-badge',
    imports: [],
    templateUrl: './badge.component.html',
    styleUrl: './badge.component.scss',
    host: {
        class: 'flex flex-col justify-center items-center w-40 h-40 bg-primary rounded-full rotate-12 p-5',
        '[class]': 'classes()',
    }
})
export class BadgeComponent {
    block = input.required<IBadgeBlock>();
    private _tailwind = inject(TailwindService);
    classes = computed(() => {
        const block = this.block();
        return [...this._tailwind.getSpacingClasses(block)];
    });
}
