import { inject, Injectable, signal } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PlatformService } from '@seven1/angular/ssr';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private _document = inject(DOCUMENT);
    private _platformService = inject(PlatformService);
    public navOpen = signal(false);

    toggleNav() {
        this.navOpen.set(!this.navOpen());
        this.lockBody()
    }

    openNav() {
        this.navOpen.set(true);
        this.lockBody();
    }

    closeNav() {
        this.navOpen.set(false);
        this.lockBody();
    }

    lockBody() {
        if (this._platformService.isBrowser) {
            if (window.innerWidth <= 768) {
                if (this.navOpen()) {
                    this._document?.body?.classList?.add('overflow-hidden');
                } else {
                    this._document?.body?.classList?.remove('overflow-hidden');
                }
            }
        }
    }
}
