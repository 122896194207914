import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { IButtonBlock } from './button.model';
import { CachedUrlResolverPipe, SbLinkHelper } from '@seven1/angular-storyblok/link';
import { DomSanitizer } from '@angular/platform-browser';
import { TailwindService } from '../../../../service';
import { DesanitizeSlashPipe } from '@seven1/angular/utils';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-button',
    imports: [NgClass, DesanitizeSlashPipe, RouterLink, CachedUrlResolverPipe, NgOptimizedImage],
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class]': 'classes()',
    }
})
export class ButtonComponent {
    private _tailwind = inject(TailwindService);
    private _sanitizer = inject(DomSanitizer);

    block = input.required<IButtonBlock>();

    classes = computed(() => {
        const block = this.block();
        return [
            'app-button',
            'app-button--color-' + block.color,
            ...this._tailwind.getSpacingClasses(block),
        ];
    });

    sanitizedLink = computed(() => {
        return SbLinkHelper.sanitizeLink(this.block().link, this._sanitizer);
    });
}
