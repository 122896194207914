import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ITariffOptionCardBlock } from './tariff-option-card.model';
import { CmsComponent } from '@seven1/angular-storyblok/components';
import { ButtonComponent } from '../../../../cta';

@Component({
    selector: 'app-tariff-option-card',
    imports: [CmsComponent, ButtonComponent],
    templateUrl: './tariff-option-card.component.html',
    styleUrl: './tariff-option-card.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-tariff-option-card',
    }
})
export class TariffOptionCardComponent {
    block = input.required<ITariffOptionCardBlock>();
}
