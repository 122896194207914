import { ISbAppLink } from '@seven1/model';
import { Sanitizer, SecurityContext } from '@angular/core';

export class SbLinkHelper {
    static sanitizeLink(link: ISbAppLink, sanitizer: Sanitizer): string {
        let sanitized = sanitizer.sanitize(SecurityContext.URL, link.cached_url || '');
        if (sanitized && link.anchor) {
            sanitized += `#${link.anchor}`;
        }
        return sanitized || '';
    }
}
