import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'desanitizeSlash',
    standalone: true,
})
export class DesanitizeSlashPipe implements PipeTransform {
    transform(value: string | undefined | null): string | undefined {
        while (value?.includes('%2F')) {
            value = value?.replace('%2F', '/');
        }
        return value || undefined;
    }
}
