@let blok = block();

@if (blok.phoneNumber) {
    <a [href]="sanitizedPhoneNumber()" class="flex items-center gap-3" [ngClass]="blok?.variant==='dark'?'text-primary':'text-white'">
        <img
            [ngSrc]="blok?.variant==='dark'?'/icons/phone_black.svg':'/icons/phone_white.svg'"
            width="18"
            height="18"
            alt="Call"
        />
        {{ blok.phoneNumber }}
    </a>
}
