@let blok = block();

@if (blok.promoted) {
    <section class="app-tariff-option-card--promoted">
        {{blok.promotedText}}
    </section>
}
@if (blok.title || blok.subtitle) {
    <section class="app-tariff-option-card--header">
        @if (blok.title) {
            <h4 class="app-tariff-option-card--title">
                {{blok.title}}
            </h4>
        }
        @if (blok.subtitle) {
            <p class="app-tariff-option-card--subtitle">
                {{blok.subtitle}}
            </p>
        }
        @if (blok.actions?.length) {
            @for (action of blok.actions; track action._uid) {
                <app-button [block]="action" class="mt-3">
                </app-button>
            }
        }
    </section>
}
@if (blok.price?.length || blok.priceSubline?.length) {
    <section class="app-tariff-option-card--price-section">
        @if (blok.price) {
            <h5 class="app-tariff-option-card--price">
                {{blok.price}}
            </h5>
        }
        @if (blok.priceSubline) {
            <p class="app-tariff-option-card--price-subline">
                {{blok.priceSubline}}
            </p>
        }
    </section>
}
@if (blok.content?.length) {
    <section class="app-tariff-option-card--content">
        @for (content of blok.content; track content._uid) {
            <s1-cms-component [blok]="content">
            </s1-cms-component>
        }
    </section>
}
