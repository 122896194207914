import { ChangeDetectionStrategy, Component, computed, inject, input, SecurityContext, ViewEncapsulation } from '@angular/core';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { IPhoneNumberBlock } from './phone-number.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-phone-number',
    imports: [NgOptimizedImage, NgClass],
    templateUrl: './phone-number.component.html',
    styleUrl: './phone-number.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'flex',
    },
})
export class PhoneNumberComponent {
    private _sanitizer = inject(DomSanitizer);
    block = input.required<IPhoneNumberBlock>();

    sanitizedPhoneNumber = computed(() => {
        const blok = this.block();
        return this._sanitizer.sanitize(SecurityContext.URL, 'tel:' + blok.phoneNumber);
    });
}
