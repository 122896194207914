import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cachedUrlResolver',
    standalone: true,
})
export class CachedUrlResolverPipe implements PipeTransform {
    transform(cached_url: string | undefined | null, prefix = '/'): string[] {
        if (prefix) {
            return cached_url ?  [prefix, ...cached_url.split('/')] : [];
        } else {
            return cached_url ?  cached_url.split('/') : [];
        }
    }
}
